import { BaseModel } from "./BaseModel"

class TaskTemplate extends BaseModel {
  constructor({ id, title, created_at, projects, activities, description, updated_at } = {}) {
    super({ id, created_at, updated_at })
    
    this.title = title
    this.description = description
    this.projects = projects
    this.activities = activities
  }
}

export default TaskTemplate;
