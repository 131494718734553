import { BASE_API } from '@/api/v1'
import BaseController from './BaseController'

class TasksOwnersController extends BaseController {
    constructor() {
        super()
    }

    async update(data) {
        return await super.create(`${BASE_API}tasks-owners`, data)
    }
}

export default TasksOwnersController