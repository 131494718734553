import RoleController from '@/controllers/RoleController'
const { log } = require('@/utils/log');

const roleController = new RoleController()

export default {
  /**
   * Represents the state of each element
   * @property {array} the state of patients
   * @property {object} the state of the selected patient
   */
  state: {
    roles: [],
  },
  mutations: {
    /**
     * Change the state of the patients
     * @param {object} departaments
     * @param {object} the data passed to our mutation
     */
    SET_ROLES(state, payload) {
      state.roles = payload
    },
  },
  actions: {
    /**
     * commits the mutations in patients
     *  @param {function} the mutation to be commited
     */
    async getAllRoles({ commit }) {
      commit('SET_LOADING', true)
      try {
        const res = await roleController.getAll()
        commit('SET_ROLES', res)
      }
      catch (e) {
        log('error')
        commit('SET_ERROR', true)
      }
      finally {
        commit("SET_LOADING", false)
      }
    },
  }
}