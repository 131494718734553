import DepartamentController from "@/controllers/DepartamentsController";
const { log } = require('@/utils/log');

const depC = new DepartamentController()

export default {
    /**
     * Represents the state of each element
     * @property {array} the state of patients
     * @property {object} the state of the selected patient
     */
    state: {
        departaments: [],
    },
    mutations: {
        /**
         * Change the state of the patients
         * @param {object} departaments
         * @param {object} the data passed to our mutation
         */
        SET_DEPARTAMENTS(state, payload) {
            state.departaments = payload
        },
    },
    getters: {
        orgDepartments: (state) => (id) => {
            return state.departaments.filter(item => item.organization.owner.id === id)
        }
    },
    actions: {
        /**
         * commits the mutations in patients
         *  @param {function} the mutation to be commited
         */
        async getAllDepartaments({ commit }) {
            commit('SET_LOADING', true)
            try {
                const res = await depC.getDepartaments()
                commit('SET_DEPARTAMENTS', res)
            } catch (err) {
                log(err)
                commit('SET_ERROR', true)
            } finally {
                commit("SET_LOADING", false)
            }
        },
        async deleteDepartament({ commit }, id) {
            commit('SET_LOADING', true)
            try {
                await depC.deleteDepartaments(id)
            } catch (err) {
                log(err)
                commit('SET_ERROR', true)
            } finally {
                commit("SET_LOADING", false)
            }
        }
    }
}