import { BaseModel } from './BaseModel'
import Project from './project'
import Task from './task'
import User from './user'
import TaskActivity from './taskActivity'

class Activity extends BaseModel {
  constructor({ id, title, status, start_date, end_date, fk_project, project, responsible, tasks, responsible_id, tasks_activities, created_at, updated_at } = {}) {
    super({ id, created_at, updated_at })

    this.title = title
    this.status = status
    this.start_date = start_date
    this.end_date = end_date

    this.projectId = fk_project
    this.responsibleId = responsible_id
    this.tasks = tasks.map(task => new Task(task))
    this.project = new Project(project)
    this.responsible = new User(responsible)
    this.tasksActivities = tasks_activities.map(task => new TaskActivity(task))
  }
}

export default Activity