import jwt from "@/auth/jwt/useJwt";
import { BASE_API } from '@/api/v1'
import Organization from "@/models/organization";

const axios = jwt.axiosIns;

class OrganizationsController {
  async getOrganization(id) {
    return await axios.get(BASE_API + 'organizations/' + id);
  }

  async getOrganizations() {
    const res = await axios.get(BASE_API + 'organizations');
    if (res.status !== 200)
      throw new Error(res)
    return res.data.map(item => new Organization({ ...item }))
  }

  async createOrganizations(data) {
    return await axios.post(BASE_API + 'organizations/create', data);
  }

  async updateOrganizations(id, data) {
    return await axios.put(BASE_API + 'organizations/' + id + '/update', data)
  }

  async deleteOrganizations(id) {
    return await axios.delete(BASE_API + 'organizations/' + id + '/delete')
  }
}

export default OrganizationsController;