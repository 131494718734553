import { BaseModel } from './BaseModel'
import TaskOwner from './taskOwner'

class Task extends BaseModel {
  constructor({ id, title, description, price, status_timer, date_timer, timer, duration, status, priority, activity, fk_activity, owners, messages, created_at, updated_at } = {}) {
    super({ id, created_at, updated_at })

    this.title = title
    this.description = description
    this.price = price
    this.timer = timer
    this.duration = duration
    this.status = status
    this.priority = priority
    this.date_timer = date_timer
    this.status_timer = status_timer

    this.fk_activity = fk_activity

    this.owners = owners ? owners.map(owner => new TaskOwner({ ...owner, ...owner.user })) : []
    this.messages = messages ? messages : []
    this.activity = activity
  }
}

export default Task