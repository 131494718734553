import TaskActivityController from '@/controllers/TaskActivityController'
import { log } from '@/utils/log'

const taskActivityController = new TaskActivityController()

export default {
  state: {
    tasksActivity: [],
    taskActivity: null,
    // tempoSalvo: {},
  },

  mutations: {
    SET_TASKS(state, payload) {
      state.tasksActivity = payload
    },

    SET_TASK(state, payload) {
      state.taskActivity = payload
    },
    REMOVE_TASK(state, payload) {
      state.tasksActivity = state.tasksActivity.filter(task => task.id !== payload.id)
    }
  },

  // getters: {
  //   getDepartamentTasks: state => id => {
  //     return state.tasksActivity.filter(task => {
  //       return task.project.departaments.some(departament => departament.owner.id === id)
  //     })
  //   },

  //   getProjManagerTasks: state => id => {
  //     return state.tasksActivity.filter(task => task.project.owner.id === id)
  //   },

  //   getEmployeeTasks: state => id => {
  //     return state.tasksActivity.filter(task => task.owner.id === id)
  //   }
  // },

  actions: {
    async createTaskActivity({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const res = await taskActivityController.create(payload)
        if (res.status !== 201) throw new Error(res)
      } catch (e) {
        log('Error in create task Activity', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async editTaskActivity({ commit }, payload) {
      try {
        await taskActivityController.edit(payload)
      } catch (e) {
        log('Error in edit task activity', e)
        throw e;
      }
    },

    setTasksActivity({ commit }, payload) {
      commit('SET_TASKS', payload)
    },

    setTaskActivity({ commit }, payload) {
      commit('SET_TASK', payload)
    },

    async getAllTaskActivity({ commit }) {
      commit('SET_LOADING', true)

      try {
        const res = await taskActivityController.getAll()
        commit('SET_TASKS', res)
      } catch (e) {
        log('Error in getAll task activity', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async getTaskActivity({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const res = await taskActivityController.get(payload)
        commit('SET_TASK', res)
      } catch (e) {
        log('Error in get task activity', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async updateTaskActivity({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await taskActivityController.update(payload)
      } catch (e) {
        log('Error in delete task activity', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async deleteTaskActivity({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const res = await taskActivityController.delete(payload)
        commit('REMOVE_TASK', res)
      } catch (e) {
        log('Error in delete task activity', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
}
