import jwt from "@/auth/jwt/useJwt";
import { BASE_API } from '@/api/v1'
import BaseController from './BaseController'
import Activity from '@/models/activity'

const axios = jwt.axiosIns;

class ActivitiesController extends BaseController {
  constructor() {
    super()
  }

  async create(payload) {
    return super.create(BASE_API + 'activities/create', payload)
  }

  async getAll() {
    const res = await super.read(BASE_API + 'activities')
    return res.map(data => new Activity(data))
  }

  async get(id) {
    const res = await super.read(BASE_API + 'activities/' + id)
    return new Activity(res)
  }

  async update(data) {
    return await super.update(BASE_API + 'activities/' + data.id + '/update', data)
  }

  async delete(id) {
    return await super.delete(BASE_API + 'activities/' + id + '/delete')
  }

  async getActivity(id) {
    const res = await axios.get(BASE_API + 'activities/' + id)
    return res;
  }

  async deleteActivity(id) {
    return await axios.delete(BASE_API + 'activities/' + id + '/delete')
  }
}

export default ActivitiesController
