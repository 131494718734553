import UserController from "@/controllers/UserController"
const { log } = require('@/utils/log');

const userC = new UserController()

export default {
    /**
     * Represents the state of each element
     * @property {array} the state of patients
     * @property {object} the state of the selected patient
     */
    state: {
        users: [],
        currentUser: null
    },
    mutations: {
        /**
         * Change the state of the patients
         * @param {object} departaments
         * @param {object} the data passed to our mutation
         */
        SET_USERS(state, payload) {
            state.users = payload
        },
        SET_CURRENT_USER(state, payload) {
            state.currentUser = payload
        }
    },
    getters: {
        departmentUsers: (state) => (departaments) => {
            let users = []
            departaments.forEach(departament => {
                departament.members.forEach(member => {
                    const user = state.users.find(user => user.id === member.id)
                    if (user) users.push(user)
                })
            })
            return users
        }
    },
    actions: {
        async verifyEmail(context, payload) {
            context.commit('SET_LOADING', true);
            try {
                await userC.verifyEmail(payload);
            } catch (err) {
                log(err);
                throw err;
            } finally {
                context.commit('SET_LOADING', false);
            }
        },
        async resendEmail(context, payload) {
            context.commit('SET_LOADING', true);
            try {
                await userC.resendEmail(payload);
            } catch (err) {
                log(err);
                throw err;
            } finally {
                context.commit('SET_LOADING', false);
            }
        },
        /**
         * commits the mutations in patients
         *  @param {function} the mutation to be commited
         */
        async updateUser({ commit }, payload) {
            commit('SET_LOADING', true)
            try {
                await userC.postUser(payload)
            } catch (err) {
                log(err)
                commit('SET_ERROR', true)
            } finally {
                commit("SET_LOADING", false)
            }
        },
        async getAllUsers({ commit }) {
            commit('SET_LOADING', true)
            try {
                const users = await userC.getAllUsers()
                commit('SET_USERS', users)
            }
            catch {
                log('error')
                commit('SET_ERROR', true)
            }
            finally {
                commit("SET_LOADING", false)
            }
        },
        async deleteUser({ commit }, payload) {
            commit('SET_LOADING', true)
            try {
                const res = await userC.deleteUser(payload)
                return res
            }
            catch {
                log('error')
                commit('SET_ERROR', true)
            }
            finally {
                commit("SET_LOADING", false)
            }
        },
        async setCurrentUser({ commit }, payload) {
            commit('SET_CURRENT_USER', payload)
        }
    }
}