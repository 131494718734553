import TasksActivitiesOwners from "@/controllers/tasksActivitiesOwners";
import { log } from "@/utils/log";

const tasksActivitiesOwners = new TasksActivitiesOwners();

export default {
    actions: {
        async updateTaskActivityOwner(context, payload) {
            try {
                await tasksActivitiesOwners.update(payload)
            } catch (e) {
                log(e)
                throw e;
            }
        },
    }
}