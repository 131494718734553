import ActivitiesController from "@/controllers/ActivitiesController"
const { log } = require('@/utils/log');

const activitiesController = new ActivitiesController()

export default {
    /**
     * Represents the state of each element
     * @property {array} the state of activities
     * @property {object} the state of the selected activity
     */
    state: {
        activities: [],
        activity: null,
    },
    mutations: {
        /**
         * Change the state of the activities
         * @param {object} activities
         * @param {object} the data passed to our mutation
         */
        SET_ACTIVITIES(state, payload) {
            state.activities = payload
        },

        SET_ACTIVITY(state, payload) {
            state.activity = payload
        },

        REMOVE_ACTIVITY(state, payload) {
            state.activities = state.activities.filter(activity => activity.id !== payload.id)
        }
    },
    getters: {
        getDepartamentActivities: state => id => {
            return state.activities.filter(activity => {
                return activity.project.departaments.some(departament => departament.owner.id === id)
            })
        },
        getProjManagerActivities: state => id => {
            return state.activities.filter(activity => activity.project.owner.id === id)
        },
        getEmployeeActivities: state => id => {
            return state.activities.filter(activity => activity.tasks.some(task => task.owners.length > 0 && task.owners.some(o => o.id === id)) ||
                activity.tasksActivities.some(task => task.owners.length > 0 && task.owners.some(o => o.id === id)))
        },
        getOrgActivities: state => id => {
            return state.activities.filter(activity => {
                return activity.project.departaments.some(departament => departament.organization.owner.id === id)
            })
        }
    },
    actions: {
        async getAllActivities({ commit }) {
            commit('SET_LOADING', true)

            try {
                const res = await activitiesController.getAll()
                commit('SET_ACTIVITIES', res)
                return res
            } catch (e) {
                log('Error in get all activities', e)
            } finally {
                commit('SET_LOADING', false)
            }
        },

        async getActivity({ commit }, payload) {
            commit('SET_LOADING', true)

            try {
                const res = await activitiesController.get(payload)
                commit('SET_ACTIVITY', res)
            } catch (e) {
                log('error in get activity', e)
            } finally {
                commit('SET_LOADING', false)
            }
        },

        /**
         * commits the mutations in activities
         *  @param {function} the mutation to be commited
         */
        async createActivity({ commit }, payload) {
            commit('SET_LOADING', true)

            try {
                await activitiesController.create(payload)
            }
            catch (e) {
                log('error', e)
                commit('SET_ERROR', true)
            }
            finally {
                commit("SET_LOADING", false)
            }
        },

        async updateActivity({ commit }, payload) {
            commit('SET_LOADING', true)

            try {
                await activitiesController.update(payload)
            } catch (e) {
                log('error', e)
                commit('SET_ERROR', true)
            } finally {
                commit("SET_LOADING", false)
            }
        },

        async deleteActivityById({ commit }, payload) {
            commit('SET_LOADING', true)

            try {
                const res = await activitiesController.delete(payload)
                commit('REMOVE_ACTIVITY', res)
            } catch (e) {
                log('Error in delete activity', e)
                commit('SET_ERROR', true)
            } finally {
                commit('SET_LOADING', false)
            }
        }
    }

}