import { BaseModel } from "./BaseModel";

class User extends BaseModel {
  constructor({ id, name, email, roles, avatar, department, status, created_at, updated_at, email_verified_at, email_disabled_at } = {}) {
    super({ id, created_at, updated_at })

    this.name = name
    this.email = email
    this.roles = roles
    this.avatar = avatar ?? null
    this.department = department ?? []
    this.status = status
    this.email_verified_at = email_verified_at
    this.email_disabled_at = email_disabled_at
  }
}

export default User