import BaseController from './BaseController'
import { BASE_API } from '@/api/v1'
import User from '@/models/user'

class UserController extends BaseController {
  constructor() {
    super()
  }

  async getAllUsers() {
    const res = await super.read(BASE_API + 'users')
    return res.map(item => new User({ ...item }))
  }

  async getUser(id) {
    return await super.read(BASE_API + 'users/' + id)
  }

  async postUser(data) {
    return await super.create(BASE_API + 'users', data)
  }

  async deleteUser(id) {
    return await super.delete(BASE_API + 'users/' + id)
  }

  async verifyEmail(url) {
    return await super.read(url);
  }

  async resendEmail(payload) {
    return await super.create(BASE_API + 'email/resend', payload);
  }
}

export default UserController