const departamentsRoutes = [
  {
    path: '/departaments',
    name: 'departaments',
    component: () => import('@/views/Departaments.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION'],
      pageTitle: 'Departaments',
      breadcrumb: [
        {
          text: 'Departaments',
          active: true,
        }
      ]
    }
  },
  {
    path: '/departaments/create',
    name: 'departamentsCreate',
    component: () => import('@/views/DepartamentsCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION'],
      pageTitle: 'Create a Departament',
      breadcrumb: [
        {
          text: 'Departaments',
          active: true,
        }
      ]
    }
  },
  {
    path: '/departaments/:id/update',
    name: 'departaments/edit',
    component: () => import('@/views/DepartamentsCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION'],
      pageTitle: 'Update a Departament',
      breadcrumb: [
        {
          text: 'Departaments',
          active: true,
        }
      ]
    }
  },
]

export default departamentsRoutes