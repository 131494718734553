import { BASE_API } from '@/api/v1'
import BaseController from './BaseController'
import TaskActivity from '@/models/taskActivity'

class TaskActivityController extends BaseController {

  async create(data) {
    return await super.create(BASE_API + 'taskActivity', data)
  }

  async getAll() {
    const res = await super.read(BASE_API + 'taskActivity')
    return res.map(data => new TaskActivity(data))
  }

  async get(id) {
    const res = await super.read(BASE_API + 'taskActivity/' + id)
    return new TaskActivity(res)
  }

  async edit(data) {
    const res = await super.edit(BASE_API + 'taskActivity/' + data.id, data)
    return new TaskActivity(res)
  }

  async update(data) {
    return await super.update(BASE_API + 'taskActivity/' + data.id, data)
  }

  async delete(id) {
    const res = await super.delete(BASE_API + 'taskActivity/' + id)
    return new TaskActivity(res)
  }
}

export default TaskActivityController