import Vue from 'vue'
import VueRouter from 'vue-router'
import departamentsRoutes from './system/departamentos'
import usersRoutes from './system/user'
import tasksRoutes from './system/tasks'
import publicRoutes from './system/public'
import projectsRoutes from './system/projects'
import activitiesRoutes from './system/activities'
import organizationsRoutes from './system/organizations'
import LogRoutes from './system/logs'
import store from "@/store";
import AuthController from "@/controllers/AuthController";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...activitiesRoutes,
    ...departamentsRoutes,
    ...usersRoutes,
    ...tasksRoutes,
    ...publicRoutes,
    ...projectsRoutes,
    ...organizationsRoutes,
    ...LogRoutes,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  let u = store.state.usersConfig.currentUser;

  if (!u) {
    if (to.name !== 'login' && to.name !== 'signup' && to.name !== 'verify-email') {
      const authController = new AuthController();
      try {
        u = await authController.CheckValidation();
        if (u) {
          store.dispatch('setCurrentUser', u);
        } else {
          next({ name: "login" });
          return;
        }
      } catch (error) {
        // Handle authentication error, such as showing a message or redirecting to an error page.
      }
    }
  }

  const currentRouteName = to.name;

  if (u) {
    const user_role = u.roles[0].name;
    if (to.meta.roles == null || to.meta.roles == undefined) {
      next();
      return;
    } else if (to.meta.roles.includes(user_role)) {
      next();
      return;
    } else {
      next({ name: "home" });
      return;
    }
  }

  if (currentRouteName === "login" ||
    currentRouteName === "signup" ||
    currentRouteName === 'verify-email'
  ) { // Corrigimos a condição aqui
    next();
  } else {
    next({ name: "login" });
  }
})

export default router
