import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import departamentConfig from './services/departaments'
import usersConfig from './services/users'
import activityConfig from './services/activity'
import projectConfig from './services/project'
import taskConfig from './services/task'
import taskMessagesConfig from './services/taskMessages'
import changelogConfig from './services/changelog'
import organizationConfig from './services/organizations'
import taskTemplateConfig from './services/taskTemplate'
import taskActivityConfig from './services/taskActivity'
import roleConfig from './services/role'
import projectsConfig from './services/projects'
import taskOwnerConfig from './services/taskOwner'
import taskActivityOwnerConfig from './services/taskActivityOwner'

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    loading: false,
    error: false,
  },  
  mutations:{
    SET_ERROR(state,payload){
      state.error = payload
    },

    SET_LOADING(state,payload){
      state.loading = payload
    }
  },
  modules: {
    projectConfig,
    activityConfig,
    taskConfig,
    usersConfig,
    app,
    appConfig,
    verticalMenu,
    departamentConfig,
    taskMessagesConfig,
    changelogConfig,
    organizationConfig,
    taskTemplateConfig,
    taskActivityConfig,
    roleConfig,
    projectsConfig,
    taskOwnerConfig,
    taskActivityOwnerConfig
  },
  strict: process.env.DEV,
})
