const projectsRoutes = [
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/Projects.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT'],
      pageTitle: 'Projects',
      breadcrumb: [
        {
          text: 'Projects',
          active: true,
        }
      ]
    }
  },
  {
    path: '/projects/create',
    name: 'projects/create',
    component: () => import('@/views/ProjectsCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT'],
      pageTitle: 'Create a Project',
      breadcrumb: [
        {
          text: 'Projects',
          active: false,
          to: '/projects'
        },
        {
          text: 'Create a Project',
          active: true,
        }
      ]
    }
  },
  {
    path: '/projects/edit/:id',
    name: 'projects/edit',
    component: () => import('@/views/ProjectsCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT'],
      pageTitle: 'Edit project',
      breadcrumb: [
        {
          text: 'Projects',
          active: false,
          to: '/projects'
        },
        {
          text: 'Edit a project',
          active: true,
        }
      ]
    }
  },
  {
    path: '/projects/:id',
    name: 'projects-detail',
    component: () => import('@/views/ProjectsDetail.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT'],
      pageTitle: 'Projects',
      breadcrumb: [
        {
          text: 'Projects',
          active: false,
          to: '/projects'
        },
        {
          text: 'Project detail',
          active: true,
        }
      ]
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/SignUp.vue'),
    meta: {
      pageTitle: 'Register',
      breadcrumb: [
        {
          text: 'Projects',
          active: false,
          to: '/projects'
        },
        {
          text: 'Register',
          active: true,
        }
      ]
    }
  },
]

export default projectsRoutes