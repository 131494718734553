import jwt from "@/auth/jwt/useJwt";

const axios = jwt.axiosIns;


class BaseController {
    constructor() { }

    /**
    * Sends a new set of data to be stored on the database via axios POST method
    * @param {String} path - API endpoint
    * @param {Array|Object} payload - array|object of whats inside the chosen file
    */
    async create(path, payload) {
        return await axios.post(path, payload)
    }

    /**
    * Request and store data from a api via axios GET method
    * @param {String} path - API endpoint
    * @returns {Array|Object} an array|object of elements representing the data stored within the database.
    */
    async read(path) {
        const res = await axios.get(path)
            .catch(err => {
                throw new Error('New error message', { cause: err })
            })
        return await res.data
    }

    async edit(path, payload) {
        const res = await axios.patch(path, payload, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        })
            .catch(err => {
                throw new Error('New error message', { cause: err })
            })
        return await res.data
    }

    async update(path, payload) {
        const res = await axios.put(path, payload)
            .catch(err => {
                throw new Error('New error message', { cause: err })
            })
        return await res.data
    }

    async delete(payload) {
        const res = await axios.delete(payload)
            .catch(error => {
                throw new Error('New error message', { cause: error })
            })
        return await res.data
    }

}

export default BaseController
