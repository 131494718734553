import jwt from "@/auth/jwt/useJwt";
import { BASE_API } from '@/api/v1'
import Departament from "@/models/departament";

const axios = jwt.axiosIns;

class DepartamentController {

  async getDepartament(id) {
    return await axios.get(BASE_API + 'departamentos/' + id)
  }

  async getDepartaments() {
    const res = await axios.get(BASE_API + 'departamentos')
    return res.data.map(item => new Departament({ ...item }))
  }

  async postDepartaments(data) {
    return await axios.post(BASE_API + 'departamentos/create', data)
  }

  async putDepartaments(id, data) {
    return await axios.put(BASE_API + 'departamentos/' + id + '/update', data)
  }

  async deleteDepartaments(id) {
    return await axios.delete(BASE_API + 'departamentos/' + id + '/delete')
  }

}

export default DepartamentController