import jwt from "@/auth/jwt/useJwt";
import { BASE_API } from '@/api/v1'
import BaseController from './BaseController'
import Task from '@/models/task'
const { log } = require('@/utils/log')

const axios = jwt.axiosIns;

class TasksController extends BaseController {
    async create(data) {
        return await super.create(BASE_API + 'tasks/create', data)
    }

    async edit(data) {
        const res = await super.edit(BASE_API + 'tasks/' + data.id, data)
        return new Task(res)
    }

    async delete(id) {
        const res = await super.delete(BASE_API + 'tasks/' + id + '/delete')
        return new Task(res)
    }

    async getTasks() {
        return await axios.get(BASE_API + 'tasks')
    }

    async showTasks(id) {
        const res = await axios.get(BASE_API + 'tasks/' + id)
        if (res.status !== 200) throw new Error(res);
        return new Task(res.data)
    }

    async postTasks(data) {
        try {
            return await axios.post(BASE_API + 'tasks/create', data)
        } catch (error) {
            log(error)
        }
    }

    async putTasks(id, data) {
        try {
            return await axios.put(BASE_API + 'tasks/' + id + '/update', data)
        } catch (error) {
            log(error)
        }
    }

    async deleteTasks(id) {
        return await axios.delete(BASE_API + 'tasks/' + id + '/delete')
    }

}

export default TasksController