const activitiesRoutes = [
  {
    path: '/activities/create',
    name: 'activities create',
    component: () => import('@/views/ActivitiesCreate.vue'),
    meta: {
      requiresAuth: true,
      roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER'],
      pageTitle: 'Activities create',
      breadcrumb: [
        {
          text: 'View activities create',
          active: true,
        }
      ]
    }
  },
  {
    path: '/activities',
    name: 'activities',
    component: () => import('@/views/Activities.vue'),
    meta: {
      requiresAuth: true,
      roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER', 'EMPLOYEE'],
      pageTitle: 'Activities',
      breadcrumb: [
        {
          text: 'View activities',
          active: true,
        }
      ]
    }
  },
  {
    path: '/activities/:id',
    name: 'activity-details',
    component: () => import('@/views/ActivityDetails.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER', 'EMPLOYEE'],
      pageTitle: 'Activity details',
      breadcrumb: [
        {
          text: 'View activities',
          active: false,
          to: { name: 'activities' }
        },
        {
          text: 'Activity details',
          active: true,
        }
      ]
    }
  },
  {
    path: '/activities/edit/:id',
    name: 'activities/edit',
    component: () => import('@/views/ActivitiesCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER'],
      pageTitle: 'Activities edit',
      breadcrumb: [
        {
          text: 'View activities edit',
          active: true,
        }
      ]
    }
  },
]

export default activitiesRoutes