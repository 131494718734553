import { BASE_API } from '@/api/v1'
import BaseController from './BaseController'
import TaskTemplate from '@/models/taskTemplate'

class TaskTemplateController extends BaseController {

  async create(data) {
    return await super.create(BASE_API + 'taskTemplate', data)
  }

  async getAll() {
    const res = await super.read(BASE_API + 'taskTemplate')
    return res.map(data => new TaskTemplate(data))
  }

  async get(id) {
    const res = await super.read(BASE_API + 'taskTemplate/' + id)
    return new TaskTemplate(res)
  }

  async update(data) {
    return await super.update(BASE_API + 'taskTemplate/' + data.id, data)
  }

  async delete(id) {
    const res = await super.delete(BASE_API + 'taskTemplate/' + id)
    return new TaskTemplate(res)
  }
}

export default TaskTemplateController