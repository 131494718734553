const organizationsRoutes = [
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/Organizations.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN'],
      pageTitle: 'Organizations',
      breadcrumb: [
        {
          text: 'Organizations',
          active: true,
        }
      ]
    }
  },
  {
    path: '/organizations/create',
    name: 'organizations/create',
    component: () => import('@/views/OrganizationCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN'],
      pageTitle: 'Create a Organization',
      breadcrumb: [
        {
          text: 'Organizations',
          active: false,
          to: '/organizations'
        },
        {
          text: 'Create a Organization',
          active: true,
        }
      ]
    }
  },
  {
    path: '/organizations/edit/:id',
    name: 'organizations/edit',
    component: () => import('@/views/OrganizationCreate.vue'),
    meta: {
      requiresAuth: true,
      roles: ['SUPERADMIN'],
      pageTitle: 'Organizations',
      breadcrumb: [
        {
          text: 'Organizations',
          active: false,
          to: '/organizations'
        },
        {
          text: 'Edit a Organization',
          active: true,
        }
      ]
    }
  },
]

export default organizationsRoutes