import TasksOwnersController from "@/controllers/tasksOwnersController";
import { log } from "@/utils/log";

const tasksOwners = new TasksOwnersController();

export default {

    actions: {
        async updateTaskOwner(context, payload) {
            try {
                await tasksOwners.update(payload)
            } catch (e) {
                log(e)
                throw e;
            }
        },
    },
};
