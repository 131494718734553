class Organization {
  constructor({ id, title, owner, logo, created_at, members, description, updated_at } = {}) {
    this.id = id;
    this.title = title;
    this.owner = owner ?? null;
    this.members = members ?? [];
    this.logo = logo ?? null;
    this.created_at = created_at;
    this.description = description;
    this.updated_at = updated_at;
  }
}

export default Organization;
