import ProjectController from "@/controllers/ProjectsController";
import { log } from "@/utils/log";

const pController = new ProjectController();

export default {
    state: {
        projects: [],
        project: null
    },
    mutations: {
        SET_PROJECTS(state, payload) {
            state.projects = payload
        },
        SET_PROJECT(state, payload) {
            state.project = payload
        }
    },
    getters: {
        orgProjects: (state) => (id) => {
            return state.projects.filter(project => project.departaments.some(departament => departament.organization.owner.id === id))
        }
    },
    actions: {
        async getProjects(context, payload) {
            try {
                const projects = await pController.getProjects(payload);
                context.commit('SET_PROJECTS', projects)
            } catch (err) {
                log(err)
            }
        },
        async getProject(context, payload) {
            try {
                const project = await pController.getProject(payload);
                context.commit('SET_PROJECT', project)
            } catch (err) {
                log(err)
            }
        },
        async deleteProject(context, payload) {
            try {
                const response = await pController.deleteProject(payload);
                if (response.status !== 200)
                    throw new Error(response.message)
            } catch (err) {
                log(err)
            }
        }
    }
}