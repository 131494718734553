import { BaseModel } from './BaseModel'
import TaskTemplate from './taskTemplate'
import TaskOwner from './taskOwner'

class TaskActivity extends BaseModel {
  constructor({ id, created_at, updated_at, end_date, fk_task_template, fk_activity, timer, date_timer, duration, price, priority, status, owners, task_template, activity, messages, status_timer } = {}) {
    super({ id, created_at, updated_at })

    this.timer = timer
    this.date_timer = date_timer
    this.price = price
    this.status = status
    this.priority = priority
    this.duration = duration
    this.endDate = end_date

    this.owners = owners ? owners.map(owner => new TaskOwner({ ...owner, ...owner.user })) : []
    this.fk_activity = fk_activity
    this.fk_task_template = fk_task_template

    this.activity = activity
    this.task_template = task_template !== null ? new TaskTemplate(task_template) : null
    this.status_timer = status_timer
    this.messages = messages ? messages : []
  }
}

export default TaskActivity