import { BaseModel } from "./BaseModel";

class TaskOwner extends BaseModel {
  constructor({ id, name, email, roles, avatar, department, status, status_timer, date_timer, timer, created_at, updated_at, email_verified_at, email_disabled_at } = {}) {
    super({ id, created_at, updated_at })

    this.name = name
    this.email = email
    this.roles = roles
    this.avatar = avatar ?? null
    this.department = department ?? []
    this.status = status
    this.status_timer = status_timer
    this.date_timer = date_timer
    this.timer = timer
    this.email_verified_at = email_verified_at
    this.email_disabled_at = email_disabled_at
  }
}

export default TaskOwner