import TaskMessagesController from '@/controllers/TaskMessagesController'

const tmC = new TaskMessagesController()

export default {
    actions: {
        async createMessage({ _ }, payload) {
            try {
                const response = await tmC.createMessage(payload)
                if (response.status !== 201)
                    throw new Error('Error in create message')
            } catch (e) {
            }
        },
        async createTaskActivityMessage({ _ }, payload) {
            try {
                const response = await tmC.createTaskActivityMessage(payload)
                if (response.status !== 201)
                    throw new Error('Error in create message')
            } catch (e) {
            }
        }
    }
}