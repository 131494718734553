const publicRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: '',
    }
  },
]

export default publicRoutes