import router from '@/router';
import { BASE_API } from '@/api/v1';

export default class JwtService {
  axiosIns = null

  isAlreadyFetchingAccessToken = false

  subscribers = []

  constructor(axiosIns) {
    this.axiosIns = axiosIns

    // ℹ️ Add request interceptor to send the authorization header on each subsequent request after login
    this.axiosIns.interceptors.request.use(config => {
      // Retrieve token from localStorage
      const token = localStorage.getItem('access_token')

      // If token is found
      if (token) {
        // Get request headers and if headers is undefined assign blank object
        config.headers = config.headers || {}

        // Set authorization header
        // ℹ️ JSON.parse will convert token to string
        config.headers.Authorization = token ? `Bearer ${token}` : ''
      }

      // Return modified config
      return config
    })

    // ℹ️ Add response interceptor to handle 401 response
    this.axiosIns.interceptors.response.use(response => {
      return response
    }, async (error) => {
      const { config, response } = error
      const originalRequest = config

      if (response && response.status === 401) {
        if (!this.isAlreadyFetchingAccessToken) {
          this.isAlreadyFetchingAccessToken = true
          this.refreshToken().then((response) => {
            this.isAlreadyFetchingAccessToken = false
            const access_token = response.data.access_token
            localStorage.setItem('access_token', access_token)
            this.onAccessTokenFetched(access_token)
          }).catch(() => {
            this.isAlreadyFetchingAccessToken = false;

            // ℹ️ Logout user and redirect to login page
            // Remove "userData" from localStorage
            localStorage.removeItem('userData')

            // Remove "accessToken" from localStorage
            localStorage.removeItem('access_token')
            localStorage.removeItem('userAbilities')

            // If 401 response returned from api
            router.push('/login')
          })
        }
        const retryOriginalRequest = new Promise(resolve => {
          this.addSubscriber(accessToken => {
            // Make sure to assign accessToken according to your response.
            // Check: https://pixinvent.ticksy.com/ticket/2413870
            // Change Authorization header
            originalRequest.headers.Authorization = `Bearer ${accessToken}`
            resolve(this.axiosIns(originalRequest))
          })
        })
        return retryOriginalRequest
      } else {
        return Promise.reject(error)
      }
    })
  }

  onAccessTokenFetched(token) {
    this.subscribers = this.subscribers.filter(callback => callback(token))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  refreshToken() {
    return this.axiosIns
      .post(`${BASE_API}refresh`, {}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
  }
}
