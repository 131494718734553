import TaskTemplateController from "@/controllers/TaskTemplateController";
import { log } from "@/utils/log";

const taskTemplateController = new TaskTemplateController();

export default {
  state: {
    tasksTemplate: [],
    taskTemplate: null,
  },

  mutations: {
    SET_TASKS(state, payload) {
      state.tasksTemplate = payload
    },

    SET_TASK(state, payload) {
      state.taskTemplate = payload
    },

    REMOVE_TASK(state, payload) {
      state.tasksTemplate = state.tasksTemplate.filter(task => task.id !== payload.id)
    }
  },

  // getters: {
  //   getDepartamentTasks: state => id => {
  //     return state.tasksTemplate.filter(task => {
  //       return task.project.departaments.some(departament => departament.owner.id === id)
  //     })
  //   },

  //   getProjManagerTasks: state => id => {
  //     return state.tasksTemplate.filter(task => task.project.owner.id === id)
  //   },

  //   getEmployeeTasks: state => id => {
  //     return state.tasksTemplate.filter(task => task.owner.id === id)
  //   }
  // },

  actions: {
    async createTaskTemplate({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const res = await taskTemplateController.create(payload)
        if (res.status !== 201) throw new Error(res)
      } catch (e) {
        log('Error in create task template', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    setTasksTemplate({ commit }, payload) {
      commit('SET_TASKS', payload)
    },

    async getAllTaskTemplate({ commit }) {
      commit('SET_LOADING', true)

      try {
        const res = await taskTemplateController.getAll()
        commit('SET_TASKS', res)
      } catch (e) {
        log('Error in getAll task template', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async getTaskTemplate({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const res = await taskTemplateController.get(payload)
        commit('SET_TASK', res)
      } catch (e) {
        log('Error in get task template', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async updateTaskTemplate({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await taskTemplateController.update(payload)
      } catch (e) {
        log('Error in delete task template', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async deleteTaskTemplate({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const res = await taskTemplateController.delete(payload)
        commit('REMOVE_TASK', res)
      } catch (e) {
        log('Error in delete task template', e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
}
