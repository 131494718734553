const LogRoutes = [
    {
      path: '/logs',
      name: 'logs',
      component: () => import('@/views/LogTable.vue'),
      meta: {
        requiresAuth: true,
        // roles: ['SUPERADMIN'],
        pageTitle: 'Log History',
        breadcrumb: [
          {
            text: 'Logs',
            active: true,
          }
        ]
      }
    },
]

export default LogRoutes;