import TasksController from "@/controllers/TasksController";
import { log } from "@/utils/log";

const tasksController = new TasksController();

export default {
  state: {
    tasks: [],
    tempoSalvo: {},
    task: null
  },

  mutations: {
    ADD_TASK(state, payload) {
      state.tasks.push(payload)
    },

    SET_TASK(state, payload) {
      state.task = payload
    },

    SET_TASKS(state, payload) {
      state.tasks = payload
    },

    REMOVE_TASK(state, payload) {
      state.tasks = state.tasks.filter(task => task.id !== payload.id)
    }
  },

  // getters: {
  //   getDepartamentTasks: (state) => (id) => {
  //     return state.tasks.filter(task => {
  //       return task.project.departaments.some(departament => departament.owner.id === id)
  //     })
  //   },

  //   getProjManagerTasks: (state) => (id) => {
  //     return state.tasks.filter(task => task.project.owner.id === id);
  //   },

  //   getEmployeeTasks: (state) => (id) => {
  //     return state.tasks.filter(task => task.owner.id === id);
  //   }
  // },

  actions: {
    async createTask(context, payload) {
      try {
        const res = await tasksController.create(payload)
        if (res.status !== 201) throw new Error(res)
      } catch (e) {
        log('Error in create task', e)
        throw e;
      }
    },

    async editTask({ commit }, payload) {
      try {
        await tasksController.edit(payload)
      } catch (e) {
        log('Error in edit task', e)
        throw e;
      }
    },

    async updateTask(context, payload) {
      try {
        const res = await tasksController.putTasks(payload.id, payload)
        if (res.status !== 200) throw new Error(res)
      } catch (e) {
        log('Errir in update task', e)
        throw e;
      }
    },

    setTasks({ commit }, payload) {
      commit("SET_TASKS", payload);
    },

    setTask({ commit }, payload) {
      commit("SET_TASK", payload)
    },

    async getAllTasks({ commit }) {
      try {
        const res = await tasksController.getTasks();
        commit("SET_TASKS", res.data);
        return res;
      } catch (e) {
        log("Error in get all tasks", e);
      }
    },

    async getTask({ commit }, payload) {
      try {
        const task = await tasksController.showTasks(payload);
        commit("SET_TASK", task);
      } catch (e) {
        log("Error in get task", e);
        throw e;
      }
    },

    async deleteTask({ commit }, payload) {
      try {
        const res = await tasksController.delete(payload)
        commit("REMOVE_TASK", res)
      } catch (e) {
        log("Error in delete task", e)
        throw e
      }
    }
  },
};
