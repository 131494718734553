import Organization from './organization';

class Departament {
  constructor({ id, title, members, organization, owner, projects, description, created_at, icon } = {}) {
    this.id = id;
    this.title = title;
    this.members = members;
    this.organization = new Organization({ ...organization });
    this.owner = owner;
    this.projects = projects;
    this.description = description;
    this.created_at = created_at;
    this.icon = icon;
  }
}

export default Departament;