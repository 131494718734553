import BaseController from './BaseController'
import { BASE_API } from '@/api/v1'

class RoleController extends BaseController {
  constructor() {
    super()
  }

  async getAll() {
    return await super.read(BASE_API + 'roles')
  }
}

export default RoleController