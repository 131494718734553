const tasksRoutes = [
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('@/views/Tasks.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER'],
      pageTitle: 'Tasks',
      breadcrumb: [
        {
          text: 'Tasks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tasks/create',
    name: 'tasksCreate',
    component: () => import('@/views/TasksCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER'],
      pageTitle: 'Create a task',
      breadcrumb: [
        {
          text: 'Tasks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tasks/:id/update',
    name: 'tasksUpdate',
    component: () => import('@/views/TasksCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER', 'EMPLOYEE'],
      pageTitle: 'Update a task',
      breadcrumb: [
        {
          text: 'View activities',
          active: false,
          to: { name: 'activities' }
        },
        {
          text: 'Activity details',
          active: true,
        },
        {
          text: 'Tasks Update',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tasks/:id/activity',
    name: 'taskActivity',
    component: () => import('@/views/TaskActivity.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER', 'EMPLOYEE'],
      pageTitle: 'Task activities',
      breadcrumb: [
        {
          text: 'View activities',
          active: false,
          to: { name: 'activities' }
        },
        {
          text: 'Activity details',
          active: true
        },
        {
          text: 'Task activities',
          active: true
        }
      ]
    },
  },
  // {
  //   path: '/tasks/:id/logs',
  //   name: 'taskLogs',
  //   component: () => import('@/views/TaskLogs.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     // roles: ['SUPERADMIN', 'HEAD_OF_ORGANIZATION', 'HEAD_OF_DEPARTMENT', 'PROJECT_MANAGER', 'EMPLOYEE'],
  //   },
  // }
]

export default tasksRoutes