import { BaseModel } from './BaseModel'

class Project extends BaseModel {
  constructor({ id, title, description, status, departaments, created_at, updated_at, tasks_template, activities, owner, members, start_date, end_date } = {}) {
    super({ id, created_at, updated_at })

    this.title = title
    this.description = description
    this.status = status
    this.start_date = start_date
    this.end_date = end_date

    this.owner = owner
    this.members = members
    this.activities = activities
    this.departaments = departaments
    this.tasks_template = tasks_template
  }
}

export default Project