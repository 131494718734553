import ProjectController from "@/controllers/ProjectsController"
const { log } = require('@/utils/log');

const projC = new ProjectController()

export default {
    /**
     * Represents the state of each element
     * @property {array} the state of patients
     * @property {object} the state of the selected patient
     */
    state: {
        projects: [],
    },
    mutations: {
        /**
         * Change the state of the patients
         * @param {object} departaments
         * @param {object} the data passed to our mutation
         */
        SET_PROJECTS(state, payload){
            state.projects = payload
        },
    },
    actions: {
        /**
         * commits the mutations in patients
         *  @param {function} the mutation to be commited
         */
        async getAllProjects ({commit}){
            commit('SET_LOADING', true)
            try{
                const res = await projC.getProjects()
                commit('SET_PROJECTS', res)
            }
            catch(e){
                log('error')
                commit('SET_ERROR', true)
            }
            finally{
                commit("SET_LOADING", false)
            }
        },
    }
}