const usersRoutes = [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_DEPARTMENT'],
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/create',
    name: 'users/create',
    component: () => import('@/views/UsersCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_DEPARTMENT'],
      pageTitle: 'Create a User',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:id/update',
    name: 'users/edit',
    component: () => import('@/views/UsersCreate.vue'),
    meta: {
      requiresAuth: true,
      // roles: ['SUPERADMIN', 'HEAD_OF_DEPARTMENT'],
      pageTitle: 'Update a User',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/SignUp.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/EmailVerification.vue'),
    meta: {
      layout: 'full',
    },
  },
]

export default usersRoutes