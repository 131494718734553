import jwt from "@/auth/jwt/useJwt";
import { BASE_API } from '@/api/v1'
import ChangeLog from '@/models/ChangeLog'

const axios = jwt.axiosIns;

class ChangeLogController {
  async create(payload) {
    const res = await axios.post(BASE_API + 'changelogs', payload)

    if (res.status !== 201)
      throw new Error(res)

    return res.data
  }

  async getLogs(payload) {
    const res = await axios.get(`${BASE_API}changelogs/index?local=${payload.local}&entity_id=${payload.entity_id}`)

    if (res.status !== 200)
      throw new Error(res)

    return res.data.map(log => new ChangeLog(log))
  }
}

export default ChangeLogController
