import ChangeLogController from "@/controllers/LogController";
import { log } from "@/utils/log";

const clC = new ChangeLogController();

export default {
    state: {
        logs: []
    },
    mutations: {
        SET_LOGS(state, payload) {
            state.logs = payload
        }
    },
    actions: {
        async createChangelog({ _ }, payload) {
            try {
                await clC.create(payload)
            } catch (err) {
                log(err)
                throw err
            }
        },
        async getChangelogs({ commit }, payload) {
            try {
                const logs = await clC.getLogs(payload)
                const sortedLogs = logs.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at)
                })
                commit('SET_LOGS', sortedLogs)
            } catch (err) {
                log(err)
            }
        }
    }
}