import BaseController from "./BaseController";
import { BASE_API } from '@/api/v1'

class TaskMessagesController extends BaseController {
  constructor() {
    super()
  }

  async createMessage(payload) {
    return await super.create(BASE_API + 'task-messages', payload)
  }

  async createTaskActivityMessage(payload) {
    return await super.create(BASE_API + 'task-activity-messages', payload)
  }
}

export default TaskMessagesController;