import jwt from "@/auth/jwt/useJwt";
import { BASE_API } from '@/api/v1'
import Project from "@/models/project";

const axios = jwt.axiosIns;

class ProjectController {

  async getProjects() {
    const res = await axios.get(BASE_API + 'projetos');
    if (res.status !== 200)
      throw new Error(res.message)
    return res.data.map(item => new Project({ ...item }))
  }

  async deleteProject(id) {
    return await axios.delete(BASE_API + 'projetos/' + id + '/delete')
  }

  async getProject(id) {
    const res = await axios.get(BASE_API + 'projetos/' + id)
    if (res.status !== 200)
      throw new Error(res.message)
    return new Project({ ...res.data })
  }

  async updateProject(id, data) {
    return await axios.put(BASE_API + 'projetos/' + id + '/update', data)
  }

  async createProject(data) {
    return await axios.post(BASE_API + 'projetos/create', data)
  }
}

export default ProjectController