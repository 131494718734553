const { BaseModel } = require("./BaseModel");

class ChangeLog extends BaseModel {
    constructor({ id, type, local, date, action, user, created_at, updated_at } = {}) {
        super({ id, created_at, updated_at })

        this.type = type;
        this.local = local;
        this.date = date;
        this.action = action;
        this.user = user;
    }
}

export default ChangeLog;