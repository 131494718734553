import jwt from "@/auth/jwt/useJwt";
import { log } from "@/utils/log";
import { BASE_API } from '@/api/v1'
import User from "@/models/user";

const axios = jwt.axiosIns;

class AuthController {
    async CheckValidation() {
        const token = localStorage.getItem("access_token");

        if (token) {
            try {
                const response = await axios.get(BASE_API + "me");

                if (!response.data.id) {
                    await this.refreshToken();
                }
                return new User({ ...response.data });
            } catch (error) {
                log(error);
                await this.refreshToken();
            }
        } else {
            window.location.href = "/login";
        }
    }

    async refreshToken() {
        const token = localStorage.getItem("access_token");

        try {
            const res = await axios.post(BASE_API + "refresh", null);

            const newToken = res.data.access_token;
            localStorage.setItem('access_token', newToken);

        } catch (error) {
            log("Falha ao atualizar o token:", error);
            window.location.href = "/login";
        }
    }
}

export default AuthController;
